import { navigate } from "gatsby"
import React, { useState, useRef, useEffect } from "react"
import { useOnWindowScroll } from "rooks"
import Seo from "../components/seo"
import styled from "styled-components"
const ScrollTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -10px;
  z-index: 100;
`
export default function IndexPage() {
  const handleScroll = () => {
    navigate("/personal", {})
  }
  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <Seo title="Blackpower Barbie" />
      <ScrollTrigger
        onClick={handleScroll}
        ontouchmove={handleScroll}
        className="scrollmobile"
      ></ScrollTrigger>
    </>
  )
}
